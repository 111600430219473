import classNames from 'classnames';
import React, { useState } from 'react';

import logo from './logo.svg';

export default function Header() {

    const [ showNavMenu, setShowNavMenu ] = useState(false);

    return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <a className="navbar-item" href="https://notional.uk">
                    <img src={logo} className="" alt='Notional logo' />
                    <span className="logotype">Notional</span>
                </a>
                <div class="is-hidden-mobile">
                    <div>Evolutionary ideas,</div>
                    <div>Elegant execution</div>
                </div>

                <a role="button"
                    class="navbar-burger"
                    data-target="navMenu"
                    aria-label="menu"
                    aria-expanded="false"
                    onClick={() => setShowNavMenu(!showNavMenu)}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div id="navMenu" className={classNames({ "navbar-menu": true, "is-active": showNavMenu })}>
                <div className="navbar-end">
                    <a className="navbar-item" href="https://notional.uk">
                        Home
                    </a>

                    <a className="navbar-item" href='/blog'>
                        Blog
                    </a>
                    <a className="navbar-item" href='https://vyne.co'>
                        Vyne
                    </a>
                </div>
            </div>
        </nav>
    );
};
