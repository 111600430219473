import React from 'react';

import footerStyles from './footer.module.scss';
import logo from './logo.svg';

export default function Footer() {
    return (
        <footer className={footerStyles.footer}>
            <div className={footerStyles.imgContainer}>
                <img src={logo} className={footerStyles.logo} alt='Notional logo'/>
                <div className={'logotype ' + footerStyles.logotype}>Notional</div>
            </div>
            <div className={footerStyles.email + ``}><a href='mailto:hello@notional.uk'>hello@notional.uk</a></div>
            <div className={footerStyles.address}>
                <div>
                    20 Jerusalem Passage, Farringdon,<br/>
                    London EC1V
                </div>
                <div>Registration Number:  11059172</div>
            </div>
        </footer>
    );
};
