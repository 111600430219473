import React from "react"
import Helmet from "react-helmet"
import useSiteMetadata from "../static_queries/useSiteMetadata"
import layoutStyles from "./layout.module.scss"
import Header from "./Header";
import Footer from "./Footer"

export default function Layout(props) {
    const {title, description} = useSiteMetadata();
    return (
        <section
            className={`${layoutStyles.layout} ${
                props.page === "info" &&
                layoutStyles.info_page}`}
            style={{
                backgroundColor: props.bgColor,
            }}
        >
            <Helmet>
                <html lang="en"/>
                <title>{title}</title>
                <meta name="description" content={description}/>
            </Helmet>
            <Header page={props.page} title={title}/>
            <div className={layoutStyles.content}>{props.children}</div>
            <Footer></Footer>
        </section>
    )
}